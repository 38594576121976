<template>
    <div class="benchmarks" v-if="!tooFewInIndustry && hasLoaded">
        <card icon="compass"
              :headline="$t('visitors.benchmarks.headline')"
              :description="$t('visitors.benchmarks.description')">
            <hr class="visible-xs visible-sm">

            <div class="row">
                <div class="col-md-4 border-right" v-if="showConversionRate">
                    <benchmark :hasLoaded="hasLoaded"
                               :headline="$t('visitors.benchmarks.conversionRate.headline')"
                               :title1="customer.domain"
                               :value1="percent(conversionRateCustomer)"
                               :title2="industryText"
                               :value2="percent(conversionRate.industry)">
                        <template v-if="hasHealthyIntegration('Freespee') && hasHealthyIntegration('Formstack')">
                            {{$t('visitors.benchmarks.conversionRate.sourceCallAndForm')}}
                        </template>
                        <template v-else-if="hasHealthyIntegration('Freespee')">
                            {{$t('visitors.benchmarks.conversionRate.sourceCall')}}
                        </template>
                        <template v-else-if="hasHealthyIntegration('Formstack')">
                            {{$t('visitors.benchmarks.conversionRate.sourceForm')}}
                        </template>
                    </benchmark>

                    <hr class="visible-xs visible-sm">
                </div>

                <div class="border-right" :class="{'col-md-4': showConversionRate, 'col-md-6': !showConversionRate}">
                    <benchmark :hasLoaded="hasLoaded"
                               headline="Besøgstid"
							   :headline="$t('visitors.benchmarks.visitDuration')"
                               :title1="customer.domain"
                               :value1="time(averageSessionDuration.customer)"
                               :title2="industryText"
                               :value2="time(averageSessionDuration.industry)"/>

                    <hr class="visible-xs visible-sm">
                </div>

                <div :class="{'col-md-4': showConversionRate, 'col-md-6': !showConversionRate}">
                    <benchmark :hasLoaded="hasLoaded"
							   :headline="$t('visitors.benchmarks.visitsFromMobileOrTablet')"
                               :title1="customer.domain"
                               :value1="fraction(visitsFromDevice.customer)"
                               :title2="industryText"
                               :value2="fraction(visitsFromDevice.industry)"/>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
  import Benchmark from '@/app/layout/components/Benchmark'
  import Card from '@/app/shared/components/Card'
  import { mapGetters } from 'vuex'
  import moment from 'moment'

  import hasIntegration from '@/mixins/integrations/hasIntegration'

  export default {
    mixins: [hasIntegration],

    computed: {
      ...mapGetters('website', {
        benchmark: 'getBenchmark'
      }),
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

      sessions () { return this.benchmark.sessions},
      calls () { return this.benchmark.calls},
      forms () { return this.benchmark.forms},
      conversionRate () { return this.benchmark.conversionRate},
      averageSessionDuration () { return this.benchmark.averageSessionDuration},
      visitsFromDevice () { return this.benchmark.visitsFromDevice},
      hasLoaded () {
        let loaded = 0

        if (this.sessions) loaded++
        if (this.calls) loaded++
        if (this.conversionRate.industry) loaded++
        if (this.averageSessionDuration.industry) loaded++
        if (this.averageSessionDuration.customer) loaded++
        if (this.visitsFromDevice.industry) loaded++
        if (this.visitsFromDevice.customer) loaded++

        let numberOfLoads = 8

        if (!this.showConversionRate) {
          numberOfLoads = 5
        }

        return loaded >= numberOfLoads
      },
      tooFewInIndustry () {
        return !this.averageSessionDuration.industry && !this.visitsFromDevice.industry
      },
      services () {return this.customer.integrations.map(integration => integration.service.name)},
      industry () {return this.customer.industry},
      industryText () {return this.$t('visitors.benchmarks.industryLabel') + this.industry.title},

      conversionRateCustomer () {
        if (this.sessions === 0) {
          return 0
        }

        if (this.hasHealthyIntegration('Freespee') && this.hasHealthyIntegration('Formstack')) {
          return (this.calls + this.forms) / this.sessions
        }

        if (this.hasHealthyIntegration('Freespee')) {
          return this.calls / this.sessions
        }

        if (this.hasHealthyIntegration('Formstack')) {
          return this.forms / this.sessions
        }

        return 0
      },

      showConversionRate () {
        return !!(this.hasHealthyIntegration('Freespee') || this.hasHealthyIntegration('Formstack'))
      },

      integrationType () {
        let value = ''

        if (this.hasHealthyIntegration('Freespee')) {
          value = 'conversion-rate-calls-only'
        }

        if (this.hasHealthyIntegration('Formstack')) {
          value = 'conversion-rate-forms-only'
        }

        if (this.hasHealthyIntegration('Formstack') && this.hasHealthyIntegration('Freespee')) {
          value = 'conversion-rate'
        }

        return value
      }
    },

    mounted () {
      this.load()
    },

    methods: {
      load () {
        if (!this.hasLoaded) {
          this.$store.dispatch('website/benchmarks', {
            showConversionRate: this.showConversionRate,
            industryId: this.industry.id,
            integrationType: this.integrationType
          })
        }
      },

      percent (value) {
        if (typeof value === 'undefined' || typeof parseInt(value) !== 'number') {
          return '-%'
        }

        return (value * 100).toFixed(1) + '%'
      },

      time (value) {
        if (typeof value === 'undefined' || typeof parseInt(value) !== 'number') {
          return '- ' + $t('visitors.benchmarks.seconds')
        }

        const parsedValue = parseInt(value)

        if (parsedValue < 120) {
          return Math.round(value) + ' ' + $t('visitors.benchmarks.seconds')
        }

        return moment.duration(parsedValue, 'seconds').humanize()
      },

      fraction (value) {
        if (typeof value === 'undefined' || typeof parseInt(value) !== 'number') {
          return '-/10'
        }

        return Math.round(value * 10) + '/10'
      }
    },

    components: {
      Benchmark,
      Card
    }
  }
</script>
