<template>
    <div class="benchmark">
        <strong>{{headline}}</strong>
        <div v-if="hasLoaded">
            <div class="row-a">
                <span class="pull-left">{{title1}}</span>
                <p class="pull-right"><strong>{{value1}}</strong></p>
                <div class="clearfix"></div>
            </div>

            <div class="row-b">
                <span class="pull-left">{{title2}}</span>
                <p class="pull-right"><strong>{{value2}}</strong></p>
                <div class="clearfix"></div>
            </div>
        </div>

        <div v-if="!hasLoaded">
            <div class="row-a">
                <span class="pull-left">{{title1}}</span>
                <p class="pull-right mock"><strong>0000</strong></p>
                <div class="clearfix"></div>
            </div>

            <div class="row-b">
                <span class="pull-left">{{title2}}</span>
                <p class="pull-right mock"><strong>0000</strong></p>
                <div class="clearfix"></div>
            </div>
        </div>

        <div class="text-muted">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>


    .row-a {
        margin-top: 15px;
        border-bottom: 1px solid #e2e2e3;
    }

    .row-b {
        margin-top: 11px;
        margin-bottom: 5px;
    }
</style>

<script>
    export default {
      props: {
        headline: String,
        title1: String,
        value1: String,
        title2: String,
        value2: String,
        hasLoaded: {
          type: Boolean,
          default: false
        }
      }
    }
</script>